<template>
  <b-card no-body>
    <b-card-header>
      <h4>Bus Master</h4>
    </b-card-header>

    <b-card-body>
      <bus-master-filters
        @filtersUpdated="filtersChangeHandler"
      />
      <bus-master-list
        :filters="filters"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'

import BusMasterFilters from '@/components/Delivery/BusMaster/BusMasterFilters.vue'
import BusMasterList from '@/components/Delivery/BusMaster/BusMasterList.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BusMasterFilters,
    BusMasterList,
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}
</script>
