<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group label="Bus Name and Number">
          <b-form-input
            v-model="nameAndNumber"
            type="text"
            placeholder="Search Bus Name and number"
            @input="debouncedFiltersChangeHandler"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group label="Favourite">
          <custom-v-select
            v-model="favourite"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="favouriteOptions"
            label="label"
            placeholder="Select Status"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            :reduce="option => option.value"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import { debounce } from 'lodash'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    CustomVSelect,
    BFormGroup,
  },
  data() {
    return {
      nameAndNumber: null,
      favourite: [],
      favouriteOptions: [{
        label: 'Yes',
        value: 'True',
      }, {
        label: 'No',
        value: 'False',
      }],
    }
  },
  methods: {
    debouncedFiltersChangeHandler: debounce(function dummyName() {
      this.filtersChangeHandler()
    }, 500),
    filtersChangeHandler() {
      const filtersdata = {}
      if (this.nameAndNumber) {
        filtersdata.name_and_number = this.nameAndNumber
      }
      if (this.favourite) {
        filtersdata.favourite = this.favourite
      }

      this.$emit('filtersUpdated', filtersdata)
    },
  },

}
</script>
