<template>
  <div>
    <b-row>
      <b-col align="right">
        <b-button
          variant="outline-primary"
          class="mb-1"
          @click="createBus = true"
        >
          Add Bus
        </b-button>
      </b-col>
    </b-row>

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      @sort-changed="sortingChanged"
    >
      <template #cell(destination_address)="value">
        <div style="display: flex; flex-wrap: wrap;">
          <template v-if="value.item.bus_areas.length > 0">
            <span
              v-for="(areaItem, index) in value.item.bus_areas"
              :key="index"
              style="display: flex; margin-right: 10px;"
            >
              <span style="color: blue;">{{ areaItem.area.name }}</span>
              <span> &nbsp; -> &nbsp; </span>
              <span style="color: green;">{{ formatedTime(areaItem.receiving_time) }} </span>
              <span v-if="index < value.item.bus_areas.length - 1">&nbsp; | </span>
            </span>
          </template>

          <span v-else>-</span>
        </div>
      </template>

      <template #cell(photo_url)="value">
        <a
          v-if="value.item.photo_url"
          :href="value.item.photo_url"
          target="_blank"
        >
          <span>View Photo</span>
        </a>
      </template>

      <template #cell(favourite)="value">
        <feather-icon
          v-if="value.item.favourite == true"
          icon="CheckCircleIcon"
          color="green"
        />
        <feather-icon
          v-else
          icon="XCircleIcon"
          color="red"
        />
      </template>

      <template #cell(actions)="data">
        <feather-icon
          v-b-tooltip.hover="{ boundary: 'window' }"
          title="View Details"
          icon="EyeIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="displayBusDetailsId = data.item.id"
        />
        <feather-icon
          v-b-tooltip.hover="{ boundary: 'window' }"
          title="Edit Bus"
          icon="EditIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="editBusId = data.item.id"
        />
      </template>
    </b-table>

    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => { currentPage = page }"
    />

    <bus-form
      v-if="createBus"
      @modal-closed="createBus = false"
      @saved="fetchItems"
    />

    <bus-form
      v-if="editBusId"
      :bus-id="editBusId"
      @modal-closed="editBusId = null"
      @saved="fetchItems"
    />

    <bus-details
      v-if="displayBusDetailsId"
      :bus-id="displayBusDetailsId"
      @modal-closed="displayBusDetailsId = null"
    />
  </div>
</template>

<script>
import {
  BSpinner, BTable, BAlert, BButton, VBTooltip, BRow, BCol,
} from 'bootstrap-vue'
import axios from 'axios'

import dateMixin from '@/mixins/dateMixin'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import BusForm from '@/components/Delivery/BusMaster/BusForm.vue'
import BusDetails from '@/components/Delivery/BusMaster/BusDetails.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BAlert,
    BButton,
    BRow,
    BCol,
    DetailedPagination,
    BusDetails,
    BusForm,
  },
  mixins: [dateMixin],
  props: {
    filters: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      createBus: false,
      editBusId: null,
      displayBusDetailsId: null,
      loading: true,
      loadingError: null,
      results: [],
      tableFields: [
        { key: 'name_and_number', label: 'Name and Number', sortable: true },
        { key: 'destination_address', label: 'Destination Arrival Time', sortable: true },
        { key: 'photo_url', label: 'Photo', sortable: false },
        { key: 'favourite', label: 'Favourite', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'id',
      sortDesc: true,
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.fetchItems()
      }
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    fetchItems() {
      this.loading = true

      axios.post('/delivery/filtered_buses', {
        page: this.currentPage,
        per_page: this.perPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        filters: { ...this.filters },
      })
        .then(res => {
          this.results = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        }).catch(error => {
          this.loading = false
          const errorResponse = error?.response
          if (errorResponse && errorResponse.status === 404 && this.currentPage > 1) {
            this.currentPage -= 1
            this.fetchItems()
          } else {
            this.loadingError = error?.response?.data?.detail || 'Error fetching buses'
          }
        })
    },
  },
}
</script>
