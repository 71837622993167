<template>
  <b-modal
    v-model="modalShow"
    size="lg"
    scrollable
    title="Bus Details"
    @hidden="$emit('modal-closed')"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">
      <b-table-simple
        responsive
        hover
        class="compact-table mb-5"
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Name and Number
            </b-th>
            <b-td>
              {{ item.name_and_number }}
            </b-td>
            <b-th>
              Receiving Time
            </b-th>
            <b-td>
              {{ item.receiving_time && formatedTime(item.receiving_time) || '-' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Destination Address
            </b-th>
            <b-td colspan="3">
              {{ item.destination_address }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Photo
            </b-th>
            <b-td>
              <a
                v-if="item.photo"
                :href="item.photo"
                target="_blank"
              >
                <span>View Photo</span>
              </a>
              <span v-else>-</span>
            </b-td>
            <b-th>
              Favourite
            </b-th>
            <b-td>
              {{ item.favourite ? 'Yes' : 'No' }}
            </b-td>
            <b-th />
            <b-td />
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <h6>Conductors</h6>
      <b-table-simple
        responsive
        hover
        class="compact-table mb-2"
      >
        <b-tbody>
          <template v-if="item.conductors.length < 1">
            <b-td style="color: red;">
              No Conductor Added
            </b-td>
          </template>
          <template v-else>
            <b-tr>
              <b-th>
                Name
              </b-th>
              <b-th>
                Mobile
              </b-th>
            </b-tr>
            <b-tr
              v-for="(conductor, conductorIndex) in item.conductors"
              :key="conductorIndex"
            >
              <b-td>{{ conductor.name }}</b-td>
              <b-td>{{ conductor.mobile }}</b-td>
            </b-tr>
          </template>
        </b-tbody>

      </b-table-simple>

      <h6>BUS DESTINATION</h6>
      <b-table-simple
        responsive
        hover
        class="compact-table"
      >
        <b-tbody>
          <template v-if="item.bus_areas.length < 1">
            <b-td style="color: red;">
              No Bus Timing Added
            </b-td>
          </template>
          <template v-else>
            <b-tr>
              <b-th>
                DESTINATION
              </b-th>
              <b-th>
                Receving Time
              </b-th>
            </b-tr>
            <b-tr
              v-for="(area, areaIndex) in item.bus_areas"
              :key="areaIndex"
            >
              <b-td>{{ area.area.name }}</b-td>
              <b-td>{{ formatedTime(area.receiving_time) }}</b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          class="float-right"
          @click="modalShow = false"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BAlert, BSpinner, BTableSimple, BTbody, BTd, BTr, BTh,
} from 'bootstrap-vue'
import axios from 'axios'

import dateMixin from '@/mixins/dateMixin'

export default {
  components: {
    BButton,
    BAlert,
    BSpinner,
    BTableSimple,
    BTbody,
    BTd,
    BTr,
    BTh,
  },
  mixins: [dateMixin],
  props: {
    busId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      item: null,
      modalShow: true,
      loadingError: null,
      loading: true,
    }
  },
  created() {
    this.fetchDetails()
  },
  methods: {
    fetchDetails() {
      axios.get(`/delivery/buses/${this.busId}`)
        .then(res => {
          this.item = res.data
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.detail || 'Error fetching bus details'
          this.loading = false
        })
    },
  },
}
</script>
